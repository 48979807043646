import { OptionalWhitepaperSlug } from '@/modules/Whitepapers/whitepapers';
import { getUrlWithQueryParameters } from '@/utils/getUrlWithQueryParameters';
import { PATH } from 'constants/path';

export type RedirectionPreAction =
  | 'enquire_job'
  | 'create_job_alert'
  | 'save_job'
  | 'see_job_alert'
  | 'whitepaper';

export type RedirectArguments = Partial<{
  redirect_url: string | null;
  action: RedirectionPreAction | null;
  whitepaper: OptionalWhitepaperSlug;
}>;

/**
 * Determine the appropriate URL to server side or link redirect a user to if they are required to sign in.
 * @param redirect the alternative URL to redirect the user to after sign-in, if any.
 * @param action the action the user took before being redirected with this link, if any.
 */
export const getSignInUrlServerSide = (
  redirect: string | null | undefined,
  action?: RedirectionPreAction | null,
): string => {
  return getUrlWithQueryParameters(PATH.LOGIN, { redirect_url: redirect, action: action });
};

/**
 * Determine the appropriate URL to server side or link redirect a user to if they are required to sign in.
 * @param redirect the alternative URL to redirect the user to after sign-in, if any.
 * @param action the action the user took before being redirected with this link, if any.
 */
export const getOnboardingUrlServerSide = (
  redirect: string | null,
  action?: RedirectionPreAction | null,
): string => {
  return getUrlWithQueryParameters(PATH.ONBOARDING, { redirect_url: redirect, action: action });
};

/**
 * Returns the correct URL to send the user to in order to accept the new terms and conditions
 * @param redirect the URL to send the user back to if they accept
 */
export const getTermsAndConditionsUrlServerSide = (redirect: string | null): string => {
  return getUrlWithQueryParameters(PATH.ACCEPT_TERMS_AND_CONDITIONS, { redirect_url: redirect });
};

/**
 * Determine the appropriate URL to server side or link redirect a user to if they are required to register.
 * @param params additional redirection metadata.
 */
export const getRegisterUrlServerSide = (params: RedirectArguments): string => {
  return getUrlWithQueryParameters(PATH.REGISTER, params);
};

/**
 * Generate the url for client side
 * @param path
 * @param action
 * @param redirect
 * @param jobNumber
 * @param jobUrl
 * @returns
 */
// FIXME this method is questionably written as it ignores 'redirect' param in some cases
// see test cases in /utils/__tests__/urlHelper.test.ts
const generateUrlClientSide = (
  path: (typeof PATH)[keyof typeof PATH],
  action?: RedirectionPreAction,
  redirect?: string,
  jobNumber?: string,
  jobUrl?: string,
): string => {
  const { pathname, search } = window.location;

  if (action === 'enquire_job') {
    const redirectUrl = `${path}?redirect_url=${redirect}`;
    return jobNumber
      ? `${redirectUrl}&action=${action}&job_number=${jobNumber}&job_url=${jobUrl}`
      : `${redirectUrl}&action=${action}`;
  }

  if (redirect && action === 'save_job') {
    const redirectUrl = `${path}?redirect_url=${encodeURIComponent(redirect)}`;
    return `${redirectUrl}&action=${action}`;
  }

  const redirectUrl = `${path}?redirect_url=${encodeURIComponent(`${pathname}${search}`)}`;

  return action ? `${redirectUrl}&action=${action}` : redirectUrl;
};

/**
 * Determine the appropriate URL to client side route a user to if they are required to register.
 * @param action the action the user took before being shown this link, if any.
 * @param redirect the URL to redirect the user to after sign-in. Defaults to the current URL.
 */
export const getRegisterUrlClientSide = (
  action?: RedirectionPreAction,
  redirect?: string,
): string => {
  return generateUrlClientSide(PATH.REGISTER, action, redirect);
};

/**
 * Determine the appropriate URL to client side route a user to if they are required to register when enquiring for a job and the addJobNumberQueryParam feature flag is on.
 * This is used for the Doctor Profile enquiry registration flow.
 * @param jobNumber the job number to be added to the URL which will be used by the profile during registration.
 * @param redirect the URL to redirect the user to after sign-in. Defaults to the current URL.
 * @param jobUrl the URL of the job the user is enquiring about.
 */
export const getRegisterUrlClientSideWithJobNumber = (
  jobNumber: string,
  redirect?: string,
  jobUrl?: string,
): string => {
  return generateUrlClientSide(PATH.REGISTER, 'enquire_job', redirect, jobNumber, jobUrl);
};

/**
 * Determine the appropriate URL to client side route a user to if they are required to sign in.
 * @param action the action the user took before being shown this link, if any.
 * @param redirect the URL to redirect the user to after sign-in. Defaults to the current URL.
 */
export const getSignInUrlClientSide = (
  action?: RedirectionPreAction,
  redirect?: string,
): string => {
  return generateUrlClientSide(PATH.LOGIN, action, redirect);
};

/**
 * Determine the appropriate UTM query parameters to append to our jobShare links.
 * @param jobOrArticleShare used to prepend the utm_source a job and not an article.
 * @param shareUrl The job URL that we are trying to share.
 * @param jobShareMedium The medium that the job will be shared. For example: 'email'.
 * @returns The URL with the appended UTM query parameters or the original URL.
 */
export const getUtmShareUrl = (
  jobOrArticleShare: 'jobshare' | 'articleshare',
  shareUrl: string,
  jobShareMedium: 'email' | 'copy' | 'social',
): string => {
  if (typeof window === 'undefined') {
    return shareUrl;
  }

  const jobShareUrl = new URL(shareUrl);

  switch (jobShareMedium) {
    case 'email':
      // special case where we need to return with encoded components

      // first delete the params if they exist
      jobShareUrl.searchParams.delete('utm_source');
      jobShareUrl.searchParams.delete('utm_medium');

      // then manually reset them with encoding the &
      return `${jobShareUrl}?utm_source=${jobOrArticleShare}_email${encodeURIComponent(
        '&',
      )}utm_medium=email`;
    case 'copy':
      jobShareUrl.searchParams.set('utm_source', `${jobOrArticleShare}_link`);
      jobShareUrl.searchParams.set('utm_medium', 'direct');
      break;
    case 'social':
      jobShareUrl.searchParams.set('utm_source', `${jobOrArticleShare}_facebook`);
      jobShareUrl.searchParams.set('utm_medium', 'social');
      break;
    default:
      break;
  }

  return jobShareUrl.toString();
};
