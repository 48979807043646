import * as Sentry from '@sentry/nextjs';

import getFormattedInfo from './errorMonitoringHelper';

interface ClientErrorInfo {
  [p: string]: string | number | Record<string, unknown> | null;
}
/**
 * This method sends the errors to Sentry and logs it in console.warn
 * This method is only suitable for _client side_ use; when notifying Sentry of server side errors instead
 * use errorInfo.tryToNoticeError()
 *
 * @param error
 * @param info
 */
export const tryToNoticeClientError = (error: Error, info?: ClientErrorInfo): void => {
  try {
    console.warn(error);

    Sentry.captureException(error, {
      extra: getFormattedInfo(error, info),
    });
  } catch (err) {
    console.warn(
      'There was an error trying to send client side error to Sentry: \n',
      err,
      'the actual error: \n',
      error,
      'the info for the error: \n',
      info,
    );
  }
};
