export enum FeatureFlags {
  MC_1359_NEW_REGISTRATION_FLOW = 'MC-1359-new-registration-flow',
  DOT_144_USER_DASHBOARD_REDESIGN = 'DOT-144-user-dashboard-redesign',
  DOT_191_BOOK_APPOINTMENT = 'DOT-191-book-appointment',
  MC_1633_ARTICLE_CTA_POSITION = 'MC-1633-article-cta-position',
  MC_1360_REGISTRATION_REMOVE_SPECIALITY_SCREEN = 'MC-1701-registration-experiment-remove-speciality-screen',
  SC_369_MARKETING_PREFERENCES_CHANGES = 'SC-369-marketing-preferences-changes',
  A2P_188_NEW_PRACTITIONER_PROFILE_LINK = 'A2P-188-new-practitioner-profile-link',
  A2P_457_NOMAN_EASTER_EGG = 'A2P-432-Noman-Easter-Egg',
  A2P_616_ON_DEMAND_MIGRATION_TO_MEDWORLD_PROFILE = 'A2P-616-on-demand-user-migration-to-medworld-profile',
  A2P_1008_A_B_TEST_REGISTRATION_ON_PROFILE = 'A2P-1008-a-b-test-for-registration-on-new-profile',
  A2P_1485_NEW_REGISTRATION_FROM_JOB_ENQUIRY = 'A2P-1485-new-registration-from-job-enquiry',
  A2P_1897_UPDATE_REDIRECTS_FOR_PROFILE_REGISTRATION = 'A2P-1897-update-redirects-for-profile-registraion',
  A2P_1496_HOMEPAGE_PROPOSITION_AB_TEST = 'A2P-1496-homepage-proposition-ab-test',
  A2P_2013_ADD_SUBSPECIALTY_FORM_FILTER = 'A2P-2013-add-new-subspecialty-form-field-to-job-search',
  A2P_2116_ADD_JOB_NUMBER_TO_REGISTER_REDIRECT_URL = 'A2P-2116-add-jobNumber-to-redirect-register-url',
  A2P_2359_DISABLE_ENQUIRE_AND_WITHDRAW_BUTTONS = 'A2P-2359-disable-job-enquiry-buttons',
}

export type ArticleCtaPositionValues = 'TOP' | 'MIDDLE' | 'BOTTOM';
