const yup = require('yup');

const serverEnvVarSchema = yup.object({
  cmsBaseUrl: yup.string().required(),
  nodeEnv: yup.string().required(),
  appEnv: yup.string().required().oneOf(['dev', 'test', 'production']),
  recruitApiUrl: yup.string().required(),
  practitionerApiUrl: yup.string().required(),
  recruitWebUrl: yup.string().required(),
  practitionerProfileUrl: yup.string().required(),
  jobFeedBucketUrl: yup.string().required(),
  split: yup.object({
    serverKey: yup.string().required(),
  }),
  auth0: yup.object({
    domain: yup.string().required(),
    clientId: yup.string().required(),
    clientSecret: yup.string().required(),
    audience: yup.string().required(),
    baseUrl: yup.string().required(),
    issuerBaseUrl: yup.string().required(),
    secret: yup.string().required(),
  }),
  maxmind: yup.object({
    accountId: yup.string().required(),
    licenseKey: yup.string().required(),
  }),
});

const serverSideEnvVars = {
  cmsBaseUrl: process.env.CMS_BASE_URL,
  nodeEnv: process.env.NODE_ENV,
  appEnv: process.env.APP_ENV,
  recruitApiUrl: process.env.RECRUIT_API_URL,
  recruitWebUrl: process.env.RECRUIT_WEB_URL,
  practitionerApiUrl: process.env.PRACTITIONER_API_URL,
  practitionerProfileUrl: process.env.PRACTITIONER_PROFILE_URL,
  jobFeedBucketUrl: process.env.JOBS_FEED_BUCKET_URL,
  split: {
    serverKey: process.env.SPLIT_SERVER_KEY,
  },
  auth0: {
    domain: process.env.AUTH0_DOMAIN,
    clientId: process.env.AUTH0_CLIENT_ID,
    clientSecret: process.env.AUTH0_CLIENT_SECRET,
    audience: process.env.AUTH0_AUDIENCE,
    baseUrl: process.env.AUTH0_BASE_URL,
    issuerBaseUrl: process.env.AUTH0_ISSUER_BASE_URL,
    secret: process.env.AUTH0_SECRET,
  },
  maxmind: {
    accountId: process.env.MAXMIND_ACCOUNT_ID,
    licenseKey: process.env.MAXMIND_LICENSE_KEY,
  },
};

const clientEnvVarSchema = yup.object({
  nextBuildNumber: yup.string().required(),
  webDomain: yup.string().required(),
  recruitWebUrl: yup.string().required(),
  practitionerProfileUrl: yup.string().required(),
  isLive: yup.boolean().required(),
  mixPanelToken: yup.string().required(),
  split: yup.object({
    clientKey: yup.string().required(),
  }),
});
// Note accessed env vars should have the prefix NEXT_PUBLIC_
const clientEnvVars = {
  nextBuildNumber: process.env.NEXT_PUBLIC_BUILD_NUMBER,
  webDomain: process.env.NEXT_PUBLIC_WEB_DOMAIN,
  isLive: process.env.NEXT_PUBLIC_IS_LIVE,
  mixPanelToken: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  recruitWebUrl: process.env.NEXT_PUBLIC_RECRUIT_WEB_URL,
  practitionerProfileUrl: process.env.NEXT_PUBLIC_PRACTITIONER_PROFILE_URL,
  split: {
    clientKey: process.env.NEXT_PUBLIC_SPLIT_CLIENT_KEY,
  },
};

function validateServerSideEnvVariables() {
  return serverEnvVarSchema.validateSync(serverSideEnvVars);
}

module.exports = {
  validateServerSideEnvVariables,
  clientEnvVars,
  clientEnvVarSchema,
  serverEnvVarSchema,
  serverSideEnvVars,
};
