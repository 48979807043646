import { NAVIGATION_MAP } from '@/modules/LandingHeader/config';
import { CmsNavItem } from '@/types/cms';
import { NavItem } from '@/types/navigation';
import { getNavigationItems } from '@/utils/browserCMSAccess';
import { tryToNoticeClientError } from '@/utils/clientErrorInfo';
import { createContext, useEffect, useState } from 'react';

export const NavigationContext = createContext<NavItem[]>([]);

export function mapCmsNavData(data?: CmsNavItem[]): NavItem[] {
  if (!data) {
    return [];
  }
  return data.map<NavItem>(item => {
    const configItem = NAVIGATION_MAP[item.id];
    return {
      id: item.id,
      label: item.displayName ? item.displayName : configItem.label,
      to: configItem.to,
      trackEventName: configItem.trackEventName,
      testid: configItem.to,
      subItems: item?.childItems
        ? item.childItems.map(subItem => ({
            label: subItem.title,
            to: `${configItem.to}/${subItem.slug}`,
          }))
        : [],
    };
  });
}

export const NavigationContextProvider = ({ children }) => {
  // Try to get the serverside cached version first, so we can serverside render it
  const initialState = global.__navigationMenuData
    ? mapCmsNavData(global.__navigationMenuData)
    : [];
  const [navItems, setNavItems] = useState<NavItem[]>(initialState);

  useEffect(() => {
    (async () => {
      if (navItems && navItems.length > 0) {
        return;
      }
      // Then try to get the serverside cached version but clientside render it
      if (window.__navigationMenuData) {
        setNavItems(mapCmsNavData(window.__navigationMenuData));
        return;
      }
      // If we couldn't get the serverside copy of the navigation data, then still try to get it from
      // the CMS clientside.
      const fetchedData: CmsNavItem[] = await getNavigationItems();

      if (fetchedData?.length > 0) {
        setNavItems(mapCmsNavData(fetchedData));
        return;
      }

      // If we get to this point then we have not been able to load the navigation menu.
      // We should alert in Sentry so we can track how often this happens.
      const error = new Error('Could not load navigation menu from serverside data or CMS');
      tryToNoticeClientError(error);
    })();
  }, []);

  return <NavigationContext.Provider value={navItems}>{children}</NavigationContext.Provider>;
};
